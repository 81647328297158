import React, { useEffect, useRef, useState } from 'react';
import { Paper, Typography, Divider, Button } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import {
  CompanyRepresentativeRoutes,
  getCompanyRepresentativePath,
} from 'domain/company-representative/CompanyRepresentativeRoutes';
import { useNavigate } from 'utilities/navigate';

import Sadad from '../images/sadad-logo.svg';

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  midleLeftSectionText: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    textAlign: 'left',
    fontSize: '1.2rem',
  },
  leftSectionText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
    textAlign: 'left',
    wordWrap: 'break-word',
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  paper: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    height: 'auto',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'auto',
    },
  },
  rightSection: {
    flex: 3,
    alignItems: 'flex-start',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      flex: 'none',
    },
  },
  leftSection: {
    flex: 1,
    padding: theme.spacing(2),
    borderLeft: `2px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      borderLeft: 'none',
      padding: theme.spacing(1),
    },
  },
  sectionHeader: {
    marginBottom: theme.spacing(1),
    textAlign: 'left',
    width: 'fit-content',
    display: 'inline-flex',
    alignItems: 'center',
    latterSpacing: '1px',
  },
  sectionText: {
    fontSize: '1.2rem',
  },
  leftSectionHeader: {
    marginBottom: theme.spacing(1),
    textAlign: 'left',
    width: 'fit-content',
  },
  sectionSubText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'left',
    wordWrap: 'break-word',
    margin: 0,
    color: 'gray',
  },
  sadadInfoHeader: {
    marginBottom: theme.spacing(1),
    textAlign: 'left',
    width: 'fit-content',
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '1.25rem',
  },
  greenText: {
    color: 'green',
  },
  statusText: {
    fontSize: '1.2rem',
    marginLeft: theme.spacing(4),
  },
  logoText: {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: '#ECF5F5',
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    borderRadius: theme.shape.borderRadius,
    fontSize: '1.1rem',
    letterSpacing: '0.8px',
  },
  logo: {
    height: 60,
    width: 80,
    // marginRight: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
  button: {
    minWidth: 300,
    marginRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  backButton: {
    minWidth: 300,
    marginRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },

  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    width: '45%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  totalAmount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  liftDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    width: '100%',
  },
}));

interface BillInfo {
  payment_id: number;
  establishment_id?: number;
  billNumber: number;
  biller_name: string;
  biller_code: number;
  amount: string;
  tax: string;
  tax_amount: string;
  total_amount: string;
  item_name: string;
}

interface SadadDetailsProps {
  billInfo: BillInfo;
  establishmentId?: string;
  status?: string;
  statusComponent?: React.ReactNode;
  onClose?: () => void;
}

const SadadDetails: React.FC<SadadDetailsProps> = ({ billInfo, establishmentId, statusComponent, onClose }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const lowerSectionHeaderRef = useRef<HTMLHeadingElement | null>(null);
  const lastTextRef = useRef<HTMLParagraphElement | null>(null);
  const [dividerWidth, setDividerWidth] = useState(0);

  useEffect(() => {
    if (lowerSectionHeaderRef.current) {
      setDividerWidth(lowerSectionHeaderRef.current.offsetWidth);
    }
  }, []);
  const formatNumber = (number: string) => {
    return parseFloat(number).toFixed(2).replace(/\.00$/, '');
  };
  return (
    <Paper className={classes.paper} style={{ marginTop: !statusComponent ? theme.spacing(10) : 0 }}>
      <div className={classes.rightSection}>
        <div>
          <Typography variant="h5" className={classes.sectionHeader}>
            {translate('sadadDetails')}
            {statusComponent && <span className={classes.statusText}>{statusComponent}</span>}
          </Typography>
          <Typography variant="body1" className={classes.sectionSubText}>
            {translate('paymentVia')}
          </Typography>
          <div className={classes.logoText}>
            <img src={Sadad} alt="sadad-logo" className={classes.logo} />
            <Typography variant="body1" className={classes.logoText}>
              {translate('sadadPaymentSystem')}
            </Typography>
          </div>
        </div>

        <div className={classes.sectionText}>
          <Divider style={{ width: dividerWidth }} className={classes.divider} />
          <Typography variant="body1" className={classes.sadadInfoHeader} ref={lowerSectionHeaderRef}>
            {translate('sadadInformation')}
          </Typography>
          <Typography variant="body1" className={classes.sectionText}>
            {translate('sadadNumber')}
            <span className={`${classes.greenText} ${classes.marginLeft}`}>{billInfo?.billNumber}</span>
          </Typography>
          <Typography variant="body1" className={classes.sectionText}>
            {translate('billerName')}
            <span className={`${classes.greenText} ${classes.marginLeft}`}>{billInfo?.biller_name}</span>
          </Typography>
          <Typography variant="body1" className={classes.sectionText}>
            {translate('billerNumber')}
            <span className={`${classes.greenText} ${classes.marginLeft}`}>{billInfo?.biller_code}</span>
          </Typography>
          <Typography variant="body1" className={classes.sectionText} ref={lastTextRef}>
            {translate('sadadNote')}
          </Typography>
          <div className={classes.buttonContainer}>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.backButton}
              onClick={() => {
                if (onClose) {
                  onClose();
                } else {
                  navigate(`${getCompanyRepresentativePath(CompanyRepresentativeRoutes.osh)}/${establishmentId}`); // Default behavior (old page)
                }
              }}
            >
              {translate('backBtn')}
            </Button>
            {/* {!statusComponent && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={() => {
                  navigate(
                    `${getCompanyRepresentativePath(CompanyRepresentativeRoutes.assessment)}/${establishmentId}${
                      CompanyRepresentativeRoutes.successPayment
                    }?billNumber=${billInfo?.billNumber}`
                  );
                }}
              >
                {translate('confirmBtn')}
              </Button>
            )} */}
          </div>
        </div>
      </div>
      <div className={classes.leftSection}>
        <div>
          <Typography variant="h5" className={classes.sectionHeader}>
            {translate('orderSummary')}
          </Typography>
        </div>
        <Divider className={classes.liftDivider} />
        <div className={classes.leftSectionText}>
          <Typography variant="h6" className={classes.sectionText}>
            {translate('serviceName')}
            <span className={`${classes.sectionText} ${classes.marginLeft}`}>{billInfo?.item_name}</span>
          </Typography>
          <Typography variant="h6" className={classes.midleLeftSectionText}>
            {translate('price')}
            <span className={`${classes.sectionText} ${classes.marginLeft}`}>
              {translate('totalPrice', { total_amount: formatNumber(billInfo?.amount) })}
            </span>
          </Typography>
          <Typography variant="h6" className={classes.sectionText}>
            {translate('taxPrice', {
              tax: `${formatNumber(billInfo?.tax)}`,
            })}
            <span className={`${classes.sectionText} ${classes.marginLeft}`}>
              {translate('totalPrice', { total_amount: formatNumber(billInfo?.tax_amount) })}
            </span>
          </Typography>
        </div>
        <Divider className={classes.liftDivider} />
        <div className={classes.leftSectionHeader}>
          <div className={classes.totalAmount}>
            <Typography variant="h6">{translate('totalAmount')}</Typography>
            <Typography variant="h6" className={`${classes.greenText} ${classes.marginLeft}`}>
              {translate('totalPrice', { total_amount: `${formatNumber(billInfo?.total_amount)}` })}
            </Typography>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default SadadDetails;
