import React from 'react';
import { useParams } from 'react-router-dom';

import { TranslationNamespace } from 'i18n/config';
import { FinishedAssessment } from 'ui/FinishedAssessment';
import { useNavigate } from 'utilities/navigate';
import { TypeOfPlatform } from 'infrastructure/services/auth.type';

import { Routes } from '../../common/Routes';

type PCRequestAssessmentFinishedProps = {
  getPath: (value: Routes) => string;
  platform?: TypeOfPlatform.OSH;
};

export const PCRequestAssessmentFinished: React.FC<PCRequestAssessmentFinishedProps> = ({
    getPath,
    platform,
}) => {
  const { assessmentId } = useParams<{ assessmentId: string; pcRequestId: string }>();
  const navigate = useNavigate();
  const { pcRequestId } = useParams<{  pcRequestId: string }>();

  const backNavigate = () => {
    navigate(`${getPath(Routes.pcRequests)}/${pcRequestId}`);
    window.location.reload();
  };

  return (
    <FinishedAssessment
      assessmentId={+assessmentId}
      handleBackNavigate={backNavigate}
      translationNamespace={TranslationNamespace.admin}
      isPcRequest
    />
  );
};