import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { Chip, ListItemText, Menu, SvgIcon, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import InvoicePopup from 'domain/company-representative/payment/InvoicePopup';
import { InvoicesTableHeader } from 'domain/company-representative/payment/InvoicesTableHeader';
import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'domain/common/utils/importLib';
import { shdShadow } from 'ui/styles';
import { ReactComponent as NoPcRequestIcon } from 'ui/icons/no-pcrequest.svg';

import { CircleButton } from './buttons/CircleButton';
import { DownloadIcon, ActionIcon, RenewInvoiceIcon, ViewDetailsIcon } from './icons/icons';
import { backButtonBackgroundColor } from './styles';
import { CustomMenuItem } from './CustomMenuItem';
import { useNewBillGenerator } from './useNewBillGenerator';
import { useFetchZATCAInvoice } from './useFetchZATCAInvoice';

const useStyle = makeStyles((theme) => ({
  table: {
    minWidth: theme.breakpoints.values.lg,
    marginTop: theme.spacing(6),
  },
  actionCell: {
    padding: '6px',
  },
  menuButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  list: {
    marginRight: theme.spacing(1),
  },
  renewIcom: {
    marginRight: theme.spacing(1),
    height: 22,
    width: 22,
  },
  svgIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    width: 40,
    backgroundColor: backButtonBackgroundColor,
  },
  transactionId: { width: 160, textAlign: 'center' },
  serviceType: { width: 120, textAlign: 'center' },
  invoiceNumber: { width: 120, textAlign: 'center' },
  expiredDate: { width: 120, textAlign: 'center' },
  invoiceAmount: { width: 150, textAlign: 'center' },
  requestsNumber: { width: 100, textAlign: 'center' },
  status: { width: 120, textAlign: 'center' },
  actions: { width: 90, textAlign: 'center' },
  noQuestionnaires: {
    boxShadow: shdShadow,
    textAlign: 'center',
  },
  noDataContainer: {
    padding: theme.spacing(12, 0),
    textAlign: 'center',
  },
  noQuestionnairesIcon: {
    height: 216,
    width: 'auto',
    fill: 'none',
  },
  noBillTitle: {
    marginTop: '34px',
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '20px',
    letterSpacing: '0.14px',
  },

  noBillMessage: {
    marginTop: theme.spacing(5),
  },
}));

type BillsInfoType = {
  payment_id: string;
  item_name: string;
  billNumber: string;
  due_date: string;
  total_amount: number;
  status: string;
  tax?: string;
  tax_amount?: number;
  biller_code?: number;
};

type BillsApiResponse = {
  count: number;
  next: null | string;
  previous: null | string;
  results: BillsInfoType[];
};

type BillsTableProps = {
  billsInfo?: BillsInfoType[] | BillsApiResponse;
};

export const BillsTable: React.FC<BillsTableProps> = ({ billsInfo = [] }) => {
  const classes = useStyle();
  const { establishmentId } = useParams<{ establishmentId: string }>();
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedBill, setSelectedBill] = useState<BillsInfoType | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const { triggerGenerateNewBill } = useNewBillGenerator(establishmentId);
  const { triggerDownloadInvoice } = useFetchZATCAInvoice();

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, bill: BillsInfoType) => {
    setAnchorEl(event.currentTarget);
    setSelectedBill(bill);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleViewPaymentDetails = () => {
    setOpenDialog(true);
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleExpiredInvoiceClick = () => {
    if (selectedBill) {
      triggerGenerateNewBill();
    }
  };

  const getStatusTranslation = (status: string) => {
    switch (status) {
      case 'Paid':
        return (
          <Chip
            label={translate('paid')}
            style={{ backgroundColor: '#e8f5e9', color: '#3B873E', width: '100%', padding: '5px' }}
          />
        );
      case 'Expired':
        return (
          <Chip
            label={translate('expired')}
            style={{ backgroundColor: '#eaeaea', color: '#696969', width: '100%', padding: '5px' }}
          />
        );
      case 'Created':
        return (
          <Chip
            label={translate('waitingForPayment')}
            style={{ backgroundColor: '#fcedc1', color: '#856620', width: '100%', padding: '5px' }}
          />
        );
      default:
        return status;
    }
  };

  const normalizedBillsInfo: BillsInfoType[] = Array.isArray(billsInfo)
    ? billsInfo
    : (billsInfo as BillsApiResponse)?.results || [];

  // Filter out failed invoices
  const filteredBillsInfo = normalizedBillsInfo.filter((bill) => bill.status !== 'Failed');

  if (filteredBillsInfo.length === 0) {
    return (
      <Paper data-testid="pcrequest-table-no-data" className={classes.noQuestionnaires}>
        <div className={classes.noDataContainer}>
          <SvgIcon className={classes.noQuestionnairesIcon} component={NoPcRequestIcon} viewBox="0 0 298.962 211.62" />
          {Array.isArray(billsInfo) && billsInfo[0]?.status === 'Failed' ? (
            <>
              <Typography className={classes.noBillTitle}>{translate('billCreationErrorTitle')}</Typography>
              <Typography className={classes.noBillMessage} variant="body2">
                {translate('billCreationError')}
              </Typography>
            </>
          ) : (
            <>
              <Typography className={classes.noBillTitle}>{translate('noBill')}</Typography>
              <Typography className={classes.noBillMessage} variant="body2">
                {translate('noBillMessage')}
              </Typography>
            </>
          )}
        </div>
      </Paper>
    );
  }

  return (
    <>
      <TableContainer className={classes.table} component={Paper}>
        <Table size="medium">
          <InvoicesTableHeader />
          <TableBody>
            {filteredBillsInfo.length > 0 &&
              filteredBillsInfo.map((bill: BillsInfoType) => (
                <TableRow key={bill.payment_id}>
                  <TableCell className={classes.transactionId}>{bill.payment_id}</TableCell>
                  <TableCell className={classes.serviceType}>{bill.item_name}</TableCell>
                  <TableCell className={classes.invoiceNumber}>{bill.billNumber}</TableCell>
                  <TableCell className={classes.invoiceNumber}>{formatDate(bill.due_date)}</TableCell>
                  <TableCell className={classes.invoiceAmount}>{Math.round(bill.total_amount)} $</TableCell>
                  <TableCell className={classes.requestsNumber}>{getStatusTranslation(bill.status)}</TableCell>
                  <TableCell className={classes.actions}>
                    <div className={classes.menuButtonWrapper}>
                      <CircleButton
                        size={20}
                        icon={ActionIcon}
                        className={classes.svgIcon}
                        viewBoxWidth={22}
                        viewBoxHeight={16}
                        onClick={(event) => handleMenuClick(event, bill)}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        {selectedBill?.status === 'Created' && (
          <CustomMenuItem onClick={handleViewPaymentDetails}>
            <SvgIcon className={classes.list} component={ViewDetailsIcon} />
            <ListItemText className={classes.list}>{translate('sadadInfo')}</ListItemText>
          </CustomMenuItem>
        )}
        {selectedBill?.status === 'Paid' && (
          <>
            <CustomMenuItem onClick={handleViewPaymentDetails}>
              <SvgIcon className={classes.list} component={ViewDetailsIcon} />
              <ListItemText className={classes.list}>{translate('sadadInfo')}</ListItemText>
            </CustomMenuItem>
            <CustomMenuItem onClick={() => selectedBill && triggerDownloadInvoice(Number(selectedBill.billNumber))}>
              <SvgIcon className={classes.list} component={DownloadIcon} />
              <ListItemText className={classes.list}>{translate('downloadInvoice')}</ListItemText>
            </CustomMenuItem>
          </>
        )}
        {selectedBill?.status === 'Expired' && (
          <CustomMenuItem onClick={handleExpiredInvoiceClick}>
            <SvgIcon className={classes.renewIcom} component={RenewInvoiceIcon} />
            <ListItemText className={classes.list}>{translate('reNewInvoice')}</ListItemText>
          </CustomMenuItem>
        )}
      </Menu>
      {selectedBill && (
        <InvoicePopup
          open={openDialog}
          onClose={handleDialogClose}
          billInfo={selectedBill}
          statusProp={selectedBill.status}
        />
      )}
    </>
  );
};
