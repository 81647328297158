import { useEffect, useRef, useState } from 'react';

import {
  useGenerateNewBillQuery,
  useGetPaymentInfoQuery,
} from 'domain/company-representative/payment/api/payments.api';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'domain/common/utils/importLib';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';

export const useNewBillGenerator = (establishmentId: string, usePaymentInfoQuery: boolean = false) => {
  const [shouldGenerateBill, setShouldGenerateBill] = useState(false);
  const handledNewBill = useRef(false);
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const showNotification = useNotifications();

  const paymentInfoResult = useGetPaymentInfoQuery(
    { establishment_id: establishmentId },
    { skip: !shouldGenerateBill || !usePaymentInfoQuery }
  );
  const generateNewBillResult = useGenerateNewBillQuery(
    { establishment_id: establishmentId },
    { skip: !shouldGenerateBill || usePaymentInfoQuery }
  );

  const newBill = usePaymentInfoQuery ? paymentInfoResult.data : generateNewBillResult.data;
  const isLoading =
    shouldGenerateBill && (usePaymentInfoQuery ? paymentInfoResult.isLoading : generateNewBillResult.isLoading);

  useEffect(() => {
    if (shouldGenerateBill && newBill && !handledNewBill.current) {
      handledNewBill.current = true;
      if (
        (newBill.message && newBill.message.trim().length > 0) ||
        (newBill.error && newBill.error.trim().length > 0) ||
        (newBill.status && newBill.status === 'Failed')
      ) {
        showNotification(NotificationType.Error, {
          message: translate('billCreationError'),
        });
      } else if (newBill.payment_id) {
        showNotification(NotificationType.Success, {
          message: translate('newSadadBillGenerated', { billNumber: newBill.billNumber.toString() }),
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
      setShouldGenerateBill(false);
    }
  }, [newBill, shouldGenerateBill, translate, showNotification]);

  const triggerGenerateNewBill = () => {
    handledNewBill.current = false;
    setShouldGenerateBill(true);
  };

  return { triggerGenerateNewBill, newBill, isLoading };
};
