import { Dialog, DialogContent, Chip } from '@material-ui/core';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import SadadDetails from 'ui/SadadDetails';

const InvoicePopup = ({ open, onClose, billInfo, statusProp }: any) => {
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);

  const getStatusTranslation = (status: string) => {
    switch (status) {
      case 'Paid':
        return (
          <Chip
            label={translate('paid')}
            style={{ backgroundColor: '#e8f5e9', color: '#3B873E', width: '100%', padding: '5px' }}
          />
        );
      case 'Expired':
        return (
          <Chip
            label={translate('expired')}
            style={{ backgroundColor: '#eaeaea', color: '#696969', width: '100%', padding: '5px' }}
          />
        );
      case 'Created':
        return (
          <Chip
            label={translate('waitingForPayment')}
            style={{ backgroundColor: '#fcedc1', color: '#856620', width: '100%', padding: '5px' }}
          />
        );
      default:
        return status;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogContent>
        <SadadDetails billInfo={billInfo} onClose={onClose} statusComponent={getStatusTranslation(statusProp)} />
      </DialogContent>
    </Dialog>
  );
};

export default InvoicePopup;
