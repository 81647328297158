import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReAuth } from 'infrastructure/persistence/baseQueryWithReAuth';
import { PaymentInfoType, Results } from 'interfaces/Results';

import { tagTypes } from './payment.tags.api';

export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  baseQuery: baseQueryWithReAuth(),
  tagTypes,
  endpoints: (build) => ({
    getPaymentInfo: build.query<PaymentInfoType, { establishment_id: string }>({
      query: ({ establishment_id }) => ({
        url: `/payment/get_payment_info/?establishment_id=${establishment_id}`,
        method: 'GET',
      }),
      providesTags: (result, error, { establishment_id }) => [{ type: 'Payment', id: establishment_id }],
    }),
    getPaymentInfoById: build.query<Results<any>, { payment_id: number }>({
      query: ({ payment_id }) => ({
        url: `/payment/get_payment_id/?payment_id=${payment_id}`,
        method: 'GET',
      }),
      providesTags: (result, error, { payment_id }) => [{ type: 'Payment', id: payment_id }],
    }),
    getAllBillEstablishment: build.query<any, { establishment_id: string }>({
      query: ({ establishment_id }) => ({
        url: `/payment/get_all_bill_establishment/?establishment_id=${establishment_id}`,
        method: 'GET',
      }),
      providesTags: (result, error, { establishment_id }) => [{ type: 'Payment', id: establishment_id }],
    }),
    getAllBillsAdmin: build.query<Results<any>, void>({
      query: () => ({
        url: '/payment',
        method: 'GET',
      }),
      providesTags: () => [{ type: 'Payment', id: 'ADMIN' }],
    }),
    generateNewBill: build.query<PaymentInfoType, { establishment_id: string }>({
      query: ({ establishment_id }) => ({
        url: `/payment/recreate_expired_bill/?establishment_id=${establishment_id}`,
        method: 'GET',
      }),
      providesTags: (result, error, { establishment_id }) => [{ type: 'Payment', id: establishment_id }],
    }),

    // * ================== ZATCA Invoice ==================

    fetchZATCAInvoice: build.query<{ invoice_data?: { bytes: string; name: string } }, { billNumber: number }>({
      query: ({ billNumber }) => ({
        url: `/payment/get_zatca_invoice/?billNumber=${billNumber}`,
        method: 'GET',
      }),
      providesTags: (result, error, { billNumber }) => [{ type: 'Invoice', id: billNumber }],
    }),
  }),
});

export const {
  useGetPaymentInfoQuery,
  useLazyGetPaymentInfoQuery,
  useGetPaymentInfoByIdQuery,
  useGetAllBillEstablishmentQuery,
  useGetAllBillsAdminQuery,
  useGenerateNewBillQuery,


// * ================== ZATCA Invoice ==================
  useFetchZATCAInvoiceQuery,
} = paymentApi;
