import { useState, useEffect, useRef } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';

import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'domain/common/utils/importLib';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { useFetchZATCAInvoiceQuery } from 'domain/company-representative/payment/api/payments.api';

export const useFetchZATCAInvoice = () => {
  const [billNumber, setBillNumber] = useState<number | null>(null);
  const handledDownload = useRef(false);
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const showNotification = useNotifications();

  const { data: invoiceData, error, isError, isFetching } = useFetchZATCAInvoiceQuery(
    billNumber !== null ? { billNumber } : skipToken
  );

  useEffect(() => {
    if (billNumber && isError && error) {
      handledDownload.current = true;

      showNotification(NotificationType.Error, {
        message: translate('invoiceDownloadError'), 
      });

      setBillNumber(null);
    }
  }, [isError, error, billNumber, translate, showNotification]);

  useEffect(() => {
    if (billNumber && invoiceData && !handledDownload.current) {
      handledDownload.current = true;

      if (!invoiceData?.invoice_data?.bytes) {
        showNotification(NotificationType.Error, {
          message: translate('invoiceDownloadError'),
        });
      } else {
        showNotification(NotificationType.Success, {
          message: translate('invoiceDownloadedSuccessfully'),
        });

        // Convert Base64 bytes to a Blob
        const byteCharacters = atob(invoiceData.invoice_data.bytes || "");
        const byteNumbers = new Uint8Array(
          Array.from(byteCharacters).map((char) => char.charCodeAt(0))
        );
        const blob = new Blob([byteNumbers], { type: 'application/pdf' });

        const fileUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = "ZATCA Invoice.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setTimeout(() => URL.revokeObjectURL(fileUrl), 5000);
      }

      setBillNumber(null);
    }
  }, [invoiceData, billNumber, translate, showNotification]);

  const triggerDownloadInvoice = (id: number) => {
    handledDownload.current = false;
    setBillNumber(id);
  };

  return { triggerDownloadInvoice, isFetching };
};