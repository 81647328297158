import { makeStyles, Container, Typography, SvgIcon, Button, CircularProgress } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { Layout } from 'ui/Layout';
import { backButtonBackgroundColor } from 'ui/styles';
import { BillsTable } from 'ui/BillsTable';
import { LoadingWrapper } from 'ui/LoadingWrapper';
import { InfoCircle } from 'ui/icons/icons';
import { useNewBillGenerator } from 'ui/useNewBillGenerator';
import { AssessmentStatus } from 'types/AssessmentStatus';

import { useGetAllBillEstablishmentQuery } from './api/payments.api';
import { useGetEstablishmentQuery } from '../api/companyRepresentative.api';

const useStyle = makeStyles((theme) => ({
  table: {
    minWidth: theme.breakpoints.values.lg,
    marginTop: theme.spacing(6),
  },

  menuButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },

  svgIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '40px',
    backgroundColor: backButtonBackgroundColor,
  },

  main: {
    padding: 0,
    marginTop: theme.spacing(15),
  },

  pageTitle: {
    marginBottom: theme.spacing(1.5),
  },

  header: {
    [theme.breakpoints.down('md')]: {
      padding: `0 ${theme.spacing(6)}px`,
    },
  },

  description: {
    marginTop: '12px',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3),
  },

  circularProgress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
  },
  billBarButton: {
    width: '170px',
    height: '40px',
    marginRight: theme.spacing(4),
  },
  billBarContainer: {
    display: 'flex',
    padding: '16px 16px 16px 16px',
    alignItems: 'flex-start',
    gap: '16px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    marginBottom: '16px',
    border: `2px solid rgba(0, 0, 0, 0.1)`,
  },
  billBarContent: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '12px',
    flex: '1 0 0',
  },
  billBarTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '5px',
    flex: '1 0 0',
  },
  billBarTitle: {
    flex: '1 0 0',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '142%',
    letterSpacing: '-0.36px',
  },
  billBarMessage: {
    flex: '1 0 0',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
  },
  billBarButtonContainer: {
    display: 'flex',
    paddingLeft: '24px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  billBarIcon: {
    width: '24px',
    height: '24px',
  },
}));

interface BillsInfoType {
  payment_id: string;
  item_name: string;
  billNumber: string;
  due_date: string;
  total_amount: number;
  status: string;
}
export const InvoicesPage: React.FC = () => {
  const classes = useStyle();
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const { establishmentId } = useParams<{ establishmentId: string }>();
  const { data: billsInfo } = useGetAllBillEstablishmentQuery({ establishment_id: establishmentId }) as {
    data: BillsInfoType[] | undefined;
  };
  const { data: establishment, isLoading } = useGetEstablishmentQuery(+establishmentId);
  const { triggerGenerateNewBill: triggerRegenerateNewBill, isLoading: isRegenerateLoading } =
    useNewBillGenerator(establishmentId);
  const { triggerGenerateNewBill: triggerGenerateNew, isLoading: isNewBillLoading } = useNewBillGenerator(
    establishmentId,
    true
  );
  const [hasInvoices, setHasInvoices] = React.useState('null');

  React.useEffect(() => {
    if (AssessmentStatus.Passed) {
      if (billsInfo && Array.isArray(billsInfo) && billsInfo.length > 0) {
        setHasInvoices('true');
      } else setHasInvoices('false');
    } else setHasInvoices('not passed');
  }, [billsInfo]);

  const handleRecreateInvoice = async () => {
    await triggerRegenerateNewBill();
  };

  const handleCreateSadadInvoice = async () => {
    await triggerGenerateNew();
  };

  const handleExpiredBill = () => {
    if (!billsInfo) return null;

    const noInvoice = hasInvoices === 'false';
    const expiredInvoice = billsInfo.length > 0 && billsInfo[0].status === 'Expired';

    if (!noInvoice && !expiredInvoice) return null;

    const title = noInvoice ? translate('createSadadInvoice') : translate('sadadBillExpiredTitle');
    const message = noInvoice
      ? translate('createSadadBillDescription')
      : translate('sadadBillExpiredMessage', { billNumber: billsInfo[0].billNumber });
    const onClickHandler = noInvoice ? handleCreateSadadInvoice : handleRecreateInvoice;

    return (
      <div
        className={classes.billBarContainer}
        style={{
          backgroundColor: noInvoice
            ? 'var(--functional-Neutral-Pending-light, #e2f1f1)'
            : 'var(--functional-Neutral-Expired-light, #FFE0E0)',
        }}
      >
        <div className={classes.billBarContent}>
          {expiredInvoice && <SvgIcon className={classes.billBarIcon} component={InfoCircle} />}
          <div className={classes.billBarTextContainer}>
            <Typography variant="body2" className={classes.billBarTitle}>
              {title}
            </Typography>
            <Typography variant="body2" className={classes.billBarMessage}>
              {message}
            </Typography>
          </div>
        </div>
        <div className={classes.billBarButtonContainer}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.billBarButton}
            onClick={() => onClickHandler()}
            disabled={noInvoice ? isNewBillLoading : isRegenerateLoading}
          >
            {(noInvoice ? isNewBillLoading : isRegenerateLoading) ? (
              <CircularProgress size={20} />
            ) : (
              translate('createNewInvoice')
            )}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Layout maxWidth="lg">
      <Container className={classes.main} maxWidth="lg">
        <div className={classes.header}>
          <Typography variant="h4" className={classes.pageTitle}>
            {translate('invoices')}
          </Typography>
        </div>
        <Typography variant="body2" className={classes.description}>
          {translate('descripeInviocePage')}
        </Typography>
      </Container>
      {handleExpiredBill()}
      <LoadingWrapper isLoading={isLoading}>{establishment && <BillsTable billsInfo={billsInfo} />}</LoadingWrapper>
    </Layout>
  );
};

export default InvoicesPage;
