import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import i18n from 'i18next';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { CustomBreadCrumbs } from 'ui/CustomBreadCrumbs';
import rootStore from 'infrastructure/store/rootStore';

import { CompanyRepresentativeRoutes, getCompanyRepresentativePath } from '../CompanyRepresentativeRoutes';
import { selectEstablishmentInfo } from '../company-representative-login/selectors/establishmentInfo.selector';
import { getDirection } from '../../../utilities/useLanguage';

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
  },

  topicTypography: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1000px',
  },

  topicDescription: {
    marginTop: theme.spacing(1.5),
    color: theme.palette.text.secondary,
  },
}));

export const CompanyRepresentativeSAHeader: React.FC = () => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const establishmentName = selectEstablishmentInfo(rootStore.getState())?.nameAr || '';
  const direction = getDirection(i18n.language);

  const saTopic = translate('saForCompanyX', {
    establishmentName,
  });

  return (
    <div dir={direction} className={classes.main}>
      <CustomBreadCrumbs
        currentLocationLabel={saTopic}
        parentCrumbs={[
          {
            label: translate('companySelection'),
            path: getCompanyRepresentativePath(CompanyRepresentativeRoutes.companySelect),
          },
        ]}
      />
      <div className={classes.topicTypography}>
        <Typography variant="h4">{saTopic}</Typography>
        <Typography variant="body2" className={classes.topicDescription}>
          {translate('saForCompanyXDescription', {
            establishmentName,
            amount: translate('servicePrice'),
          })}
        </Typography>
      </div>
    </div>
  );
};
